import React from 'react';
import { createRoot } from 'react-dom/client';

function getIconName(type: string): string {
    switch (type) {
        case 'success':
            return 'check';
        case 'info':
            return 'info';
        case 'warning':
            return 'exclamation-triangle';
        case 'danger':
            return 'exclamation-triangle';
        default:
            return '';
    }
}

export type ToastProps = {
    el: HTMLElement;
    id: string;
    seconds: number;
    text: string | React.FunctionComponent | any;
    type: string;
};

export default function Toast({ el, seconds = 4, type = 'info', text = '' }: ToastProps) {
    const unMount = () => {
        // Retrieve existing root from el's dataset if available
        const root = (el as any)._reactRoot;
        if (root) {
            root.unmount();
        }
        el.remove();
    };
    React.useEffect(() => {
        const timer = setTimeout(unMount, seconds * 1000);

        const closeByEsc = (e: any) => {
            if (e.keyCode === 27) {
                unMount();
            }
        };
        document.addEventListener('keydown', closeByEsc, true);
        return () => {
            clearTimeout(timer);
            document.removeEventListener('keydown', closeByEsc, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={'alert alert-' + type}>
            <span className="toast-loader" style={{ animationDuration: seconds * 1000 + 'ms' }} />
            <i className={'far fa-' + getIconName(type)} />
            <button type="button" className="close" onClick={unMount}>
                &times;
            </button>
            {text}
        </div>
    );
}
